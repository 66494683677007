import React, { useEffect, useState, useRef } from 'react';
import { LineChart, BarChart, PieChart } from '@mui/x-charts';
import { Grid } from '@mui/material';
import { Doughnut } from 'react-chartjs-2'; // Import Doughnut from react-chartjs-2
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";


ChartJS.register(ArcElement, Tooltip, Legend);


const DataVisualizationType = Object.freeze({
  BAR_CHART: 'bar_chart',
  LINE_CHART: 'line_chart',
  PIE_CHART: 'pie_chart',
  DOUGHNUT_CHART: 'doughnut_chart', // Add doughnut chart type
  PLAIN_TEXT: 'plain_text',
  NONE: 'none'
});

const serieColors = [
  '#4e79a7',
  '#f28e2c',
  '#59a14f',
  '#af7aa1',
  '#76b7b2',
  '#edc949',
  '#ff9da7',
  '#9c755f',
];


const ChartComponent = ({ indicator, chartType, chartData, setChartRef }) => {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({});
  const [dataType, setDataType] = useState(DataVisualizationType.NONE);
  const [loading, setLoading] = useState(true);
  const [seriesVisibility, setSeriesVisibility] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    if (setChartRef) setChartRef(chartRef.current);

    if (chartType === 'doughnut-chart') {
      // Set Doughnut chart options
      const colors = ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'brown', 'Red']

      const doughnutData = chartData.map((item, index) => ({
        label: item.label || "",  // Assuming `item` has a `label` property
        value: item.value || item,  // Default value if `value` is not present
        color: item.color ||colors[index], // Default color if not provided
        cutout: "50%"
      }));



      const finalData = {
        datasets: [
          {
            data: doughnutData.map((item) => Math.round(item.value)),
            // backgroundColor: doughnutData.map((item) => item.color),
            borderColor: doughnutData.map((item) => item.color),
            borderWidth: 1,
            dataVisibility: new Array(doughnutData.length).fill(true),
          },
        ],
      };
    
      setDataType(DataVisualizationType.DOUGHNUT_CHART);
      setOptions(finalData);
    } else if (indicator.acf.graph_data_indicators.data_display_type === 'line-graph-bar-chart') {
      let mappedObject = JSON.parse(indicator.acf.graph_data_indicators.whole_data);
      
      if (mappedObject.isBarChart) {
        setDataType(DataVisualizationType.BAR_CHART);
      } else {
        setDataType(DataVisualizationType.LINE_CHART);
      }

      const labelsObject = mappedObject.columns.find(column => column.label === "Year");
      const labelsArray = labelsObject ? labelsObject.data : mappedObject.columns[0].data;
      const otherLabels = mappedObject.columns.filter(column => column.label !== "Year");

      if (seriesVisibility.length !== otherLabels.length) {
        setSeriesVisibility(new Array(otherLabels.length).fill(true));
      }

      let series = otherLabels.map((item, index) => ({
        label: item.label,
        type: mappedObject.isBarChart ? 'bar' : 'line',
        data: item.data,
        hidden: !seriesVisibility[index],
        color: serieColors[index],
        valueFormatter: (v) => `${v} ${indicator.acf.graph_data_indicators.suffix}.`,
      }));

      setOptions({
        xData: labelsArray,
        yData: series,
      });
    } else if (indicator.acf.graph_data_indicators.data_display_type === 'pie-chart') {
      let chartData = indicator.acf.graph_data_indicators.pie_chart;
      setDataType(DataVisualizationType.PIE_CHART);
      setOptions(chartData);
    } else if (indicator.acf.graph_data_indicators.data_display_type === 'plain-text') {
      setDataType(DataVisualizationType.PLAIN_TEXT);
      setOptions({
        xData: indicator.acf.graph_data_indicators.plain_text.plain_text_label,
        yData: indicator.acf.graph_data_indicators.plain_text.value,
      });
    } else {
      setDataType(DataVisualizationType.NONE);
    }

    setLoading(false);
  }, [indicator, seriesVisibility, setChartRef]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (options.length === 0) {
    return <p>No chart data available.</p>;
  }

  const toggleSeriesVisibility = (index) => {
    setSeriesVisibility(prev => {
      const newVisibility = [...prev];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div ref={chartRef} style={{position: 'relative'}}>
            {dataType === 'line_chart' && (
              <LineChart
                slotProps={{ legend: { hidden: true } }}
                height={300}
                xAxis={[{ data: options.xData, scaleType: 'point' }]}
                series={options.yData.filter(series => !series.hidden)}
              />
            )}
            {dataType === 'bar_chart' && (
              <BarChart
                slotProps={{ legend: { hidden: true } }}
                height={300}
                xAxis={[{ data: options.xData, scaleType: 'band' }]}
                series={options.yData.filter(series => !series.hidden)}
              />
            )}
            {dataType === 'pie_chart' && (
              <PieChart
                series={[{ data: options }]}
                height={300}
              />
            )}
            {dataType === 'doughnut_chart' && (
              <Doughnut 
              data={options} 
              options={
                {
                  plugins: {
                    responsive: false,
                  },
                  cutout: data.map((item) => item.cutout),
                }
              }/>

            )}
            {dataType === 'plain_text' && (
              <div className='row-plaint-text'>
                <div className='chart-plain-text-label'>
                  {options.xData}
                </div>
                <div className='chart-plain-text-value'>
                  {options.yData}
                </div>
              </div>
            )}

            {/* Series toggle control */}
            {(dataType === 'line_chart' || dataType === 'bar_chart') && options.yData.map((item, index) => (
              <div key={item.label} style={{color: item.color}}>
                <label>
                  <input 
                    type="checkbox"
                    checked={seriesVisibility[index]}
                    onChange={() => toggleSeriesVisibility(index)}
                  />
                  {item.label}
                </label>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ChartComponent;
