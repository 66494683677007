import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { StatusIndicator } from './StatusIndicator';


const AllIndicatorsForGoalList = ({ indicators, goalId, activeIndicatorId, onIndicatorSelect }) => {
  const { indicatorId } = useParams();

  const handleIndicatorClick = (id) => {
    onIndicatorSelect(id);
  };

  return (
    <ListGroup>
      {indicators.map((indicator) => (
        <Link
          key={indicator.id}
          to={`/goal/${goalId}/indicators/${indicator.id}`}
          className={`list-group-item list-group-item-action ${indicator.id.toString() === indicatorId ? 'active' : ''}`}
          onClick={() => handleIndicatorClick(indicator.id)}
        >
          <strong>{indicator.acf.index}</strong> {indicator.acf.description}
        </Link>
      ))}
    </ListGroup>
  );
};


export default AllIndicatorsForGoalList;


