// GoalGrid.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Row, Col } from 'react-bootstrap';  
import { GoalsContext } from '../State/GoalsContext'; // Import GoalsContext
import { IndicatorsContext } from '../State/IndicatorsContext';
import './GoalGrid.css';
import { StatusIndicator } from './StatusIndicator';
import DataSquare from './DataSquare';
import { AlignmentWithSDGStatusCircleOnly }  from './AlignmentWithSDGStatus';
import { Button } from '@mui/material'; // Button from Material-UI
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import {LegendTrendHorizontalList, LegendStatusHorizontalList} from './LegendHorizontalList'; // Adjust the path as necessary
import DoughnutChart from './DoughnutChart';
import DownloadableSDGPDFPage from './DownloadableSDGPDFPage';



const Spinner = () => (
  <div className="spinner">
      {/* Your spinner markup, e.g., a loading gif or CSS spinner */}
      Loading...
  </div>
);


const GoalGrid = () => {

  const { goals, loading, setSelectedGoal } = useContext(GoalsContext);
  const { indicators, loadingIndicators, fetchAllIndicators } = useContext(IndicatorsContext);
  const [showContent, setShowContent] = useState(false);
  const pdfRef = useRef();

  const downloadPDF = () => {
    pdfRef.current.generatePDF(); // Call the generatePDF function
  };

  useEffect(() => {
    if (goals !== null) {   
      const goalIds = goals.map(goal => goal.id); 
      fetchAllIndicators(goalIds);
    }
  }, [goals]);
  

  const handleGoalClick = (goal) => {
    setSelectedGoal(goal);
  };

  const handleGoalIndicatorsClick = (goal) => {
    setSelectedGoal(goal);
  };

  if (loading) return <p>Loading...</p>;


  return (
    <Container>
      <Row>
        <Col>
          <Row className='home-header'>          
            <img src={`/sdg-logo/logo-horizontal.png`} className="sdg-logo" />
          </Row>
          <Row className="sdg-home-title">          
            <h3>Sustainable Development Goals for Kosovo</h3>
          </Row>
          <Row className='sdg-home-subtitle'>          
            <p>Click on each goal for the Kosovo UN Sustainable Development Goal public global indicator data.</p>
          </Row>

          <Col className='download-pdf'>
            <Button 
            onClick={downloadPDF} // Attach the click event here
            startIcon={<FileDownloadOutlined />} 
            variant="outlined"  
            sx={{
              backgroundColor: '#343158',
              color: 'white',          // Custom text color
              borderColor: '#1c1856',    // Custom border color
              '&:hover': {
                color: 'white',
                borderColor: 'lightGray', // Custom hover border color
                backgroundColor: '#f0412a', // Custom hover background color
              }
            }} 
            size="large" >
              Download Kosovo SDG Profile (PDF)
            </Button>
          </Col>
        </Col>

        <hr style={{margin: '0px 12px'}}/>
        
        <Col className='home-country-section'>
          <Row className='sdg-home-country-title'>          
            <p>Kosovo SDG Profile</p>
          </Row>
          <Row className='sdg-home-country-subtitle'>          
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat ex quis congue facilisis. Quisque quis porttitor nibh.</p>
          </Row>

          <Row className='sdg-home-country-data-grid'>
            <div className='data-square'>
              <div className='data-title'>SDG Index Rank</div>
              <div className='data-content'><span className='index-number'>{`${'113'}`}</span>/{`${'166'}`}</div>
            </div>
            <div style={{ width: '200px', height: '200px', marginBottom: '20px'}}>
            <div className='data-square'>
              <div className='data-title'>SDG Index Score</div>
              {/* <div className='data-content'> */}
              <DoughnutChart data={[20]} width={100} height={100} centerText='23'/> {/* Chart with specified size */}

              {/* </div> */}
            </div>

              {/* <DoughnutChart data={[80, 20, 30]} width={100} height={100} /> Larger chart */}
            </div>
          </Row>
        </Col>

      </Row>
      <hr/>

      <Row className='sdg-home-grit-title'>          
        <p>SDG Dashboards and Trends</p>
      </Row>
      <Row className='sdg-home-grit-subtitle'>          
        <p>Click on a goal to view more information.</p>
      </Row>
      
      
      <div className='section-title' style={{paddingBottom: '25px', paddingTop: '15px'}}>
        <h6 >Legend For Goal Dashboard & Trend Statuses</h6>
        <LegendTrendHorizontalList />
      </div>


      <div className="goal-grid"> 
        {goals.map(goal => (
          // Goal grid item 
          <div key={goal.id} className="goal-item">
            <div className="card-container">
              <div className="card front">
                <Row className='goal-item-image-container'>
                  <Col xs={12} md={9}>
                      <img src={goal.featured_image_url} alt={goal.title.rendered} className="goal-icon" />
                  </Col>
                  <Col xs={12} md={3} className='goal-item-status'>
                    <StatusIndicator status={goal.acf.status}></StatusIndicator>
                    <div style={{ width: '20px', height: '20px', marginBottom: '20px'}}>
                      <AlignmentWithSDGStatusCircleOnly alignmentStatus={goal.acf.alignment_with_sdg_data.status} />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="card back" style={{ backgroundColor: goal.acf.goal_color}}>
                <div className="back-content">
                  {/* Empty div container, you can fill this later */}
                  <Row className='goal-item-footer'>
                    <Link to={`/goal/${goal.id}/targets`} onClick={() => handleGoalClick(goal)}>
                      <div className="goal-grid-button-see-all-targets"  >
                        <div className='goal-grid-button-title-container'>
                          <h4>All Data</h4>
                          <p>Data for targets and indicators</p>  
                        </div>
                        <span className="chevron">→</span>
                      </div>
                    </Link>
                    <Link to={`/goal/${goal.id}/indicators`} onClick={() => handleGoalIndicatorsClick(goal)}>
                      <div className="goal-grid-button-see-all-data"  >
                        <div className='goal-grid-button-title-container'>
                          <h4>Indicators</h4>
                          <p>Data for available Indicators</p>    
                        </div>
                        <span className="chevron">→</span>
                      </div>
                    </Link>
                  </Row>

                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <hr/>

      <Row className='sdg-home-grit-title'>          
        <p>All Available Indicators</p>
      </Row>
      <Row className='sdg-home-grit-subtitle'>          
        <p>Click the indicator to view the latest data</p>
      </Row>
      <div>
          {loadingIndicators ? ( // Show spinner while loading
              <Spinner />
          ) : (
              <div className='available-indicators'>
                {goals.map((goal, index) => {
                  // Check if there are any indicators for the current goal
                  const goalIndicators = indicators.filter(indicator => indicator.goal_id === goal.id);

                  return (
                      <div key={goal.id}> 
                          {goalIndicators.length > 0 && ( // Only render section title if there are indicators
                              <div>
                              <div className="section-title">
                                  <h5>SDG {index+1}: {goal.acf.name}</h5> 
                              </div>

                              <div className="grid">
                              {goalIndicators.length > 0 ? (
                                  goalIndicators.map((indicator, index) => (
                                      <div key={index} className={`grid-item column-${(index % 2) + 1}`}>
                                          <StatusIndicator status={indicator.acf.status} fontSize={20}></StatusIndicator>
                                          <p>{indicator.title}</p>                                          
                                      </div>
                                  ))
                              ) : (
                                  <div>No indicators available for this goal.</div> // Fallback if no indicators are present
                              )}
                              </div>
                              </div>

                          )}
                          
                      </div>
                    );
                  })}
              </div>
          )}
      </div>
      <br />
      <br />
      <br />
      </Container>
  );
};

export default GoalGrid;



