import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import 'bootstrap-icons/font/bootstrap-icons.css';
import GoalInfo from './GoalInfo.js';
import AllIndicatorsForGoalList from './AllIndicatorsForGoalList.js';
import IndicatorDetails from './IndicatorDetails.js';
import Skeleton from '@mui/material/Skeleton';
import { GoalsContext } from '../State/GoalsContext.ts';
import { IndicatorsContext } from '../State/IndicatorsContext.ts';
import SingleIndicatorDetails from './SingleIndicatorDetails';


const AllGoalIndicators = () => {
  const { selectedGoal, fetchGoal, loading: goalLoading } = useContext(GoalsContext);
  const { indicators, loading: indicatorsLoading, fetchAllIndicators } = useContext(IndicatorsContext);

  const { goalId, indicatorId } = useParams();
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(indicatorId || null);
  const [selectedIndicator, setSelectedIndicator] = useState(
    indicators.find((i) => i.id === parseInt(indicatorId)) || null
  );
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (!selectedGoal || selectedGoal.id !== parseInt(goalId)) {
      fetchGoal(parseInt(goalId));
    }

    if (selectedGoal) {
      fetchAllIndicators(selectedGoal.id);
    }
  }, [goalId, selectedGoal]);

  useEffect(() => {
    if (indicatorId) {
      setSelectedIndicatorId(indicatorId);
      let indicator = indicators.find((i) => i.id === parseInt(indicatorId))
      console.log("indicator : ",indicator);
      
      setSelectedIndicator(indicator);
    }
  }, [indicatorId, indicators]);

  if (goalLoading || indicatorsLoading) {
    return (
      <div>
        <Skeleton variant="text" width={'100%'} height={5} />
      </div>
    );
  }

  if (!selectedGoal) return <p>Goal not found</p>;

  return (
    <div className="container-full">
      <Container fluid className="goal-details-container">
        <Row style={{ height: '91%' }}>
          <Col xs={12} md={4} className="targets-column">
            <GoalInfo goal={selectedGoal} />
            <Row className="targets-list">
              <div className="section-title">
                <h6>Indicators</h6>
              </div>
              <AllIndicatorsForGoalList
                indicators={indicators}
                goalId={goalId}
                activeIndicatorId={selectedIndicatorId}
                onIndicatorSelect={setSelectedIndicatorId}
              />
            </Row>
          </Col>
          {!isMobile && (
            <Col xs={12} md={8} className="indicators-column">
              <SingleIndicatorDetails indicator={selectedIndicator} />
            </Col>
          )}
        </Row>
        {isMobile && selectedIndicatorId && (
          <div className="bottom-sheet">
            <div className="bottom-sheet-header">
              <Button variant="secondary" onClick={() => setSelectedIndicatorId(null)}>
                Close
              </Button>
            </div>
            <div className="bottom-sheet-content">
              <SingleIndicatorDetails indicator={selectedIndicator} />
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default AllGoalIndicators;
