import React from 'react';
import { Routes, Route } from 'react-router-dom';
import GoalGrid from './Components/GoalGrid';
import GoalDetail from './Components/GoalDetail';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header';
// import Footer from './components/Footer';
import { GoalsProvider } from './State/GoalsProvider'; 
import TargetsProvider from './State/TargetsProvider'; 
import { IndicatorsProvider } from './State/IndicatorsProvider'; 
import AlignmentPage from './Components/AlignmentPage';
import AllGoalIndicators from './Components/AllGoalIndicators.js';

function App() {
  return (
    <GoalsProvider>
      <TargetsProvider>
        <IndicatorsProvider>
          <div className="App">
            <Header />
            <main className="flex-grow-1">
              <Routes>
                {/* More specific routes should come first */}
                <Route path="/goal/:goalId/indicators" element={<AllGoalIndicators />} />
                <Route path="/goal/:goalId/indicators/:indicatorId/*" element={<AllGoalIndicators />} />
                <Route path="/goal/:goalId/targets/:targetId/*" element={<GoalDetail />} />
                <Route path="/goal/:goalId/*" element={<GoalDetail />} />
                <Route path="/" element={<GoalGrid />} />
                <Route path="/alignment" element={<AlignmentPage />} />
              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        </IndicatorsProvider>
      </TargetsProvider>
    </GoalsProvider>
  );
}

export default App;