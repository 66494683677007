// DoughnutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

// Register the necessary elements
ChartJS.register(ArcElement, Tooltip, Legend);

// Generate random colors for the chart
const generateColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
        colors.push(`rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.7)`);
    }
    return ['#039dda']
    return colors;
};

const DoughnutChart = ({ data, width = 100, height = 100, centerText }) => {
    // Calculate the total of the data
    const total = data.reduce((sum, value) => sum + value, 0);
    
    // Ensure the total does not exceed 100%
    const adjustedData = total > 100 ? data.map(value => (value / total) * 100) : data;

    // Create chart data
    const chartData = {
        labels: [], // No labels needed
        datasets: [
            {
                data: adjustedData.concat(100 - adjustedData.reduce((a, b) => a + b, 0)), // Fill the remaining percentage
                backgroundColor: [...generateColors(adjustedData.length), '#d3d3d3'], // Last segment will be transparent
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        cutout: '80%', // Adjust this value to make the filled part smaller

        plugins: {
            tooltip: {
                enabled: false, // Disable tooltips
            },
            legend: {
                display: false, // Hide the legend
            },
        },
    };

    return (
        <div style={{ position: 'relative', width: width, height: height }}>
            <Doughnut data={chartData} options={options} />
            {centerText && (
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#039dda',
                    fontSize: '24px',
                    fontWeight: '700',
                    textAlign: 'center',
                }}>
                    {centerText}
                </div>
            )}
        </div>
    );
};

export default DoughnutChart;
