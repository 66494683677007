import React, { useEffect, useContext } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import ChartContainer from './ChartContainer';
import { StatusIndicatorWithColorText } from './StatusIndicator';
import { Button } from '@mui/material';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import { IndicatorsContext } from '../State/IndicatorsContext.ts';

const SingleIndicatorDetails = ({ indicator }) => {
    const { loading } = useContext(IndicatorsContext);

    useEffect(() => {
        if (!indicator) {
            // Fetch indicator details if needed
        }
    }, [indicator]);

    if (loading) {
        return "Loading...";
    }

    if (!indicator) {
        return <p>Please select an indicator to view details.</p>;
    }

    const preventCollapse = (e) => {
        e.stopPropagation(); // Prevent the accordion toggle behavior
    };    

    return (
        <Accordion defaultActiveKey="0" className="my-3">
            <Accordion.Item eventKey="0" className="accordion-item-container">
                <div className="accordion-header accordion-button" >

                <p><strong>{indicator.acf.index }</strong> {indicator.acf.description}</p>
                
                </div>

                <Accordion.Body>
                    <h6>Description</h6>
                    <p>{indicator.acf.description}</p>
                    <hr />
                    <h6>Long-Term Objective</h6>
                    <p>
                        The long-term objective for this indicator is{' '}
                        <strong>
                            {indicator.acf.long_term_objective} {indicator.acf.graph_data_indicators.suffix}
                        </strong>.
                    </p>
                    <hr />
                    <h6>Progress</h6>
                    <StatusIndicatorWithColorText status={indicator.acf.status} />
                    <hr />
                    <h6>Data</h6>
                    <ChartContainer
                        indicator={indicator}
                        chartType={indicator.chartType}
                        chartData={indicator.chartData}
                    />
                    <hr />
                    <h6>References</h6>
                    <p>{indicator.acf.reference_field}</p>
                    <hr />
                    <h6>Source</h6>
                    <p>
                        <a
                            href={indicator.acf.source}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link-offset-2 link-underline link-underline-opacity-25"
                        >
                            {indicator.acf.source}
                        </a>
                    </p>
                    <hr />
                    <h6>Download</h6>
                    <div className="graph-buttons">
                        <Button
                            startIcon={<FileDownloadOutlined />}
                            variant="outlined"
                            color="secondary"
                            size="small"
                        >
                            Download Data
                        </Button>
                        <Button
                            startIcon={<FileDownloadOutlined />}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="ms-2"
                        >
                            Download Graph As Image
                        </Button>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default SingleIndicatorDetails;
